import { LoginHelper } from "../../helpers/login.helper";
import {AUTH_LOGIN, AUTH_LOGIN_ERROR} from "../actionTypes";
import {uiFinishLoading, uiStartLoading} from "./ui.actions";

export const startLoginEmailPassword = (email: string, password: string) => {
    return async (dispatch: Function) => {
        dispatch(uiStartLoading());
        const resp = await new LoginHelper().loginHelper(email, password);
        if (resp) {
            dispatch(login(resp.data));
        } else {
            dispatch(loginError(true));
        }
        dispatch(uiFinishLoading());
    }
}

export const login = (data:any) => ({
    type: AUTH_LOGIN,
    payload: data

});

export const loginError = (error:boolean) => ({
    type: AUTH_LOGIN_ERROR,
    payload: error
});

