import {axiosConfigResApi} from "../config/axios.config";
const md5 = require('md5');

export class LoginHelper {

    loginHelper = async (username: string, password: string) => {

        const time = new Date().getTime();
        const body = {
            email: username,
            password
        };

        const has1 = md5(JSON.stringify(body) + time + 'mimensaje');
        try {
            return await axiosConfigResApi.post(`${process.env.REACT_APP_API_URL_LOGIN}/${has1}/${time}`, body);
        } catch (error) {
            console.log(error);
        }
    }

}