import {UI_FINISH_LOADING, UI_REMOVE_ERROR, UI_SET_ERROR, UI_START_LOADING} from "../actionTypes";

export const setErrorAction = (err:string) => ({
    type: UI_SET_ERROR,
    payload: err
});

export const removeErrorAction = () => ({
    type: UI_REMOVE_ERROR
});

export const uiStartLoading = () => ({
    type: UI_START_LOADING
});

export const uiFinishLoading = () => ({
    type: UI_FINISH_LOADING
});


