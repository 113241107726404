/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

//AUTH
export const AUTH_CHECKING = '[AUTH] Checking login state';
export const AUTH_CHECKING_FINISH = '[AUTH] Checking login state finish';
export const AUTH_LOGIN_START = '[AUTH] Checking login start';
export const AUTH_LOGIN = '[AUTH] Login';
export const AUTH_LOGIN_ERROR = '[AUTH] login error';
export const AUTH_START_REGISTER = '[AUTH] Start register';
export const AUTH_START_TOKEN_RENEW = '[AUTH] Start token renew';
export const AUTH_LOGOUT = '[AUTH] Logout';

//UI
export const UI_SET_ERROR = '[UI] set error';
export const UI_REMOVE_ERROR = '[UI] remove error';
export const UI_START_LOADING = '[UI] start loading';
export const UI_FINISH_LOADING = '[UI] finish loading';

// CAMPAIGN
export const CAMPAIGN_START_GETALL_BY_USER = '[CAMPAIGN] campaign start get all by user';
export const CAMPAIGN_GETALL_BY_USER = '[CAMPAIGN] campaign get all by user';
export const CAMPAIGN_GETALL_BY_USER_ERROR = '[CAMPAIGN] campaign get all by user error';

// TYPE CAMPAIGN
export const TYPE_CAMPAIGN_START_GETALL = '[TYPE CAMPAIGN] campaign start get all';
export const TYPE_CAMPAIGN_GETALL = '[TYPE CAMPAIGN] campaign get all';
export const TYPE_CAMPAIGN_GETALL_ERROR = '[TYPE CAMPAIGN] campaign get all error';