import React, { useState } from 'react';
import {
    Alert,
    Container,
    Row,
    Col,
    FormGroup,
    FormFeedback,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { loginError, startLoginEmailPassword } from "../../redux/actions/auth.actions";
import Logo from "../../assets/images/logo/logo.png";
import {Link} from 'react-router-dom';


export const LoginScreen = (props:any) => {

    const { loading } = useSelector((store:any) => store.ui);

    const dispatch = useDispatch();

    const { error } = useSelector((store:any) => store.auth);

    const [passwordHide, setPasswordHide] = useState(true); 


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const onSubmit = (data:any) => {

        dispatch(loginError(false));
        dispatch(startLoginEmailPassword(data.email, data.password));


    };

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <img className="img-fluid for-light"
                                    src={Logo} alt="" />
                            </div>
                            <div className="login-main login-tab">

                                <TabContent activeTab={"jwt"} className="content-login">
                                    <TabPane className="fade show" tabId={"jwt"}>

                                        <form className="theme-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                                            <h4>{"MIMENSAJEENLINEA.COM"}</h4>
                                            <p>{"Complete la siguiente información e ingrese a la plataforma."}</p>

                                            {
                                                error && <Alert color="danger">
                                                    Usuario o contraseña incorrectos
                                                </Alert>
                                            }

                                            <FormGroup>
                                                <Label className="col-form-label">Email</Label>
                                                <Input
                                                    style={
                                                        errors.email && { border: '1px red solid' }
                                                    }
                                                    autoComplete="off"
                                                    id="email"
                                                    invalid={!!errors.email}
                                                    className='form-control'
                                                    type="text"
                                                    {...register('email', {
                                                        required: { value: true, message: 'Email es requerido.' },
                                                        pattern: {
                                                            value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                                            message: 'Email no valido.'
                                                        }
                                                    })}
                                                />
                                                {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}


                                            </FormGroup>

                                            <FormGroup>
                                                <Label className="col-form-label">Contraseña</Label>
                                                <Input
                                                    style={
                                                        errors.password && { border: '1px red solid' }
                                                    }
                                                    id="password"
                                                    autoComplete="off"
                                                    invalid={!!errors.password}
                                                    className="form-control has-success"
                                                    type={passwordHide ? 'password' : 'text'}
                                                    {...register('password', {
                                                        required: {
                                                            value: true,
                                                            message: 'Contraseña es requerido'
                                                        }
                                                    })}

                                                />
                                                <div className="show-hide">
                                                    <span
                                                         className={!passwordHide ? "" : "show"}
                                                        onClick={() => {
                                                            setPasswordHide(!passwordHide);
                                                        }}
                                                    >
                                                    </span>
                                                </div>
                                                {errors.password &&
                                                    <FormFeedback>{errors.password.message}</FormFeedback>}
                                            </FormGroup>
                                            <div className="form-group mb-0">

                                                {
                                                    !loading ? (<Button type={"submit"} color="primary" className="btn-block" style={{
                                                        marginBottom: '17px'
                                                    }}>Ingresar</Button>) : (<Spinner color="primary" />)
                                                }



                                                <a href="https://index.html">Olvido su contraseña?</a>


                                            </div>



                                            <p className="mt-4 mb-0">{"No tiene una cuenta?"}
                                                <Link to="/auth/register"
                                                      className="ml-2">Crear mi cuenta</Link></p>


                                        </form>
                                    </TabPane>

                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}