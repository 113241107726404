import {AUTH_LOGIN, AUTH_LOGIN_ERROR, AUTH_LOGOUT} from "../actionTypes";

const initialState = {
    checking: false,
    error: false,
    token: null,
    user: null,
    exp: null,
    iat: null,
    roles: []
}

export const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case AUTH_LOGIN:
            return {
                ...state,
                checking: true,
                error: false,
                token: action.payload,
                user: JSON.parse(atob(action.payload.access_token.split('.')[1])).usuario,
                exp: JSON.parse(atob(action.payload.access_token.split('.')[1])).exp,
                iat: JSON.parse(atob(action.payload.access_token.split('.')[1])).iat,
                roles: JSON.parse(atob(action.payload.access_token.split('.')[1])).usuario.roles,
            }

        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                error: action.payload
            }


        case AUTH_LOGOUT:
            return {}

        default:
            return state;

    }

}