import React, {Fragment} from 'react';
import Loader from "../layout/loader";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import ThemeCustomize from "../layout/theme-customizer";
import {ToastContainer} from 'react-toastify'
import {MENUITEMS} from "../layout/sidebar/menu";

const App = ({children}) => {

  return (
    <Fragment>
      <Loader/>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header/>
      <div className="page-body-wrapper sidebar-icon">
        <Sidebar MENUITEMS={MENUITEMS} />
        <div className="page-body">
          {children}
        </div>
          { // <Footer/>
               }
          <ThemeCustomize/>
        </div>
      </div>
      <ToastContainer/>
    </Fragment>
  );
}

export default App;