import React, {useState, useEffect, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {
    ADD_COLOR,
    ADD_COSTOMIZER,
    ROUTER_ANIMATION
} from '../../redux/actionTypes'

const Themecustomizer = (props) => {

    const default_color = localStorage.getItem('default_color');
    const secondary_color = localStorage.getItem('secondary_color');
    const layout_animation = localStorage.getItem('animation');
    const configDB = useSelector(content => content.Customizer.customizer);
    const dispatch = useDispatch();
    const [layout_type] = useState(configDB.settings.layout_type);
    const [sidebar_type] = useState(configDB.settings.sidebar.type);
    const body_sidebar_type = configDB.settings.sidebar.body_type;
    const sidebar_setting = configDB.settings.sidebar_setting;
    const mix_background_layout = configDB.color.mix_background_layout;
    const config_primary = configDB.color.primary_color;
    const config_secondary = configDB.color.secondary_color;

    const width = useWindowSize()

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    useEffect(() => {

        dispatch({type: ADD_COSTOMIZER});

        dispatch({
            type: ADD_COLOR,
            payload: {
                default_color,
                secondary_color,
            }
        })

        dispatch({type: ROUTER_ANIMATION, payload: layout_animation});

        if (default_color == null || secondary_color == null) {
            document.documentElement.style.setProperty('--theme-deafult', config_primary);
            document.documentElement.style.setProperty('--theme-secondary', config_secondary);
            dispatch({
                type: ADD_COLOR,
                payload: {
                    primary_color: config_primary,
                    secondary_color: config_secondary,
                }
            })
        } else {
            document.documentElement.style.setProperty('--theme-deafult', default_color);
            document.documentElement.style.setProperty('--theme-secondary', secondary_color);
            dispatch({
                type: ADD_COLOR,
                payload: {
                    primary_color: default_color,
                    secondary_color: secondary_color,
                }
            })
        }


        document.body.className = `${mix_background_layout} ${layout_type}`
        document.documentElement.dir = layout_type;


        if (width <= 991) {
            document.querySelector(".page-wrapper").className = 'page-wrapper compact-wrapper';
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper sidebar-icon';
            document.querySelector(".page-header").className = 'page-header close_icon';
            document.querySelector(".sidebar-wrapper").className = 'sidebar-wrapper close_icon';
        } else if (width <= 991 && sidebar_type === "horizontal-wrapper") {
            document.querySelector(".page-wrapper").className = 'page-wrapper compact-wrapper'
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper sidebar-icon'
            document.querySelector(".page-header").className = 'page-header close_icon';
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
        } else {
            document.querySelector(".page-wrapper").className = 'page-wrapper ' + sidebar_type;
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper ' + body_sidebar_type;
            document.querySelector(".page-header").className = 'page-header ';
            document.querySelector(".sidebar-wrapper").className = 'sidebar-wrapper ';
        }


        document.querySelector(".sidebar-wrapper").setAttribute('sidebar-layout', sidebar_setting);

    }, [width, body_sidebar_type, config_primary, config_secondary, default_color, dispatch, layout_animation, layout_type, mix_background_layout, secondary_color, sidebar_setting, sidebar_type]);


    return (

        <>
        </>


    );
}

export default Themecustomizer;