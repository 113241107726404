import {combineReducers} from 'redux'
import Customizer from './customizer/reducer'
import {authReducer} from "./reducers/authReducer";
import {uiReducer} from "./reducers/uiReducer";
import {campaignReducer} from "./reducers/campaignReducer";
import {typeCampaignReducer} from "./reducers/typeCampaignReducer";

const reducers = combineReducers({
    Customizer,
    auth: authReducer,
    ui: uiReducer,
    campaign: campaignReducer,
    typeCampaigns: typeCampaignReducer
});

export default reducers;