import { Home, User, AlertTriangle, Folder, Send,Terminal, BarChart, MessageCircle, File } from 'react-feather';
export const MENUITEMS = [
    {
        menutitle:"Dashboard",
        menucontent:"",
        Items:[
            { path: `${process.env.PUBLIC_URL}/app/dashboard`,icon:Home, title: 'Inicio', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/ecommerce`,icon:BarChart, title: 'View Charts', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/email-app`,icon:User, title: 'Contacts', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/email-app`,icon:AlertTriangle, title: 'Blacklist', type: 'link' },
        ]
    },

    {
        menutitle:"Reports",
        menucontent:"",
        Items:[

            { path: `${process.env.PUBLIC_URL}/app/chat-app`,icon:MessageCircle, title: 'All Messages', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/email-app`,icon:Send, title: 'Sent Messages', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/file-manager`,icon:Folder, title: 'Campaigns', type: 'link' },

        ]
    },

    /*{
        menutitle:"Sending",
        menucontent:"",
        Items:[

            { path: `${process.env.PUBLIC_URL}/app/chat-app`,icon:Server, title: 'Sending Server', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/email-app`,icon: Hash, title: 'Numbers', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/file-manager`,icon:File, title: 'SMS Template', type: 'link' },

        ]
    },*/


    {
        menutitle:"SMS",
        menucontent:"",
        Items:[

            { path: `${process.env.PUBLIC_URL}/app/campaign`,icon:Folder, title: 'Campaign', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/templates/sms`,icon:File, title: 'SMS Template', type: 'link' },
           // { path: `${process.env.PUBLIC_URL}/app/email-app`,icon:Send, title: 'Quick Send', type: 'link' },
           // { path: `${process.env.PUBLIC_URL}/app/file-manager`,icon:Layers, title: 'Send Using File', type: 'link' },

        ]
    },

    {
        menutitle:">_ Developers",
        menucontent:"",
        Items:[
            { path: `${process.env.PUBLIC_URL}/app/chat-app`, icon: Terminal, title: 'Documentation', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/app/chat-app`, icon: Terminal, title: 'Integration', type: 'link' },

        ]
    },


]