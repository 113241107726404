import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import {LoginScreen} from "./components/auth/LoginScreen";
import App from "./components/app";
import {BasePage} from "./BasePage";
import Register from "./components/starter-kits";

export function Root() {


    const {isAuthorized} = useSelector(
        (store: any) => ({
            isAuthorized: store.auth.token != null,
        }),
        shallowEqual
    );

    return (
        <BrowserRouter basename={`/`}>
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <>
                        <Route path={"/auth/login"}>
                            <LoginScreen/>
                        </Route>

                        <Route path={"/auth/register"}>
                            <Register/>
                        </Route>

                        <Redirect to="/auth/login"/>
                    </>


                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth/login" to="/"/>
                )}

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login"/>
                ) : (
                    <App>
                        <BasePage/>
                    </App>
                )}
            </Switch>
        </BrowserRouter>
    );
}


