import {CAMPAIGN_GETALL_BY_USER, CAMPAIGN_GETALL_BY_USER_ERROR, CAMPAIGN_START_GETALL_BY_USER} from "../actionTypes";

export interface CampaignState {

    loading: false,
    error: null,
    campaigns: any,
}

const initialState: CampaignState = {

    loading: false,
    error: null,
    campaigns: null,
};

export const campaignReducer = (state = initialState, action:any) => {
    switch (action.type) {

        case CAMPAIGN_START_GETALL_BY_USER:
            return {
                ...state,
                loading: true
            }


        case CAMPAIGN_GETALL_BY_USER:
            return {
                ...state,
                loading: false,
                campaigns: {...action.payload}
            }

        case CAMPAIGN_GETALL_BY_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }



        default:
            return state;

    }

}