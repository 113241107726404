import {
    TYPE_CAMPAIGN_GETALL, TYPE_CAMPAIGN_GETALL_ERROR, TYPE_CAMPAIGN_START_GETALL
} from "../actionTypes";
import {TipoCampania} from "../../models/TipoCampania";

export interface TypesCampaignState {

    loading: false,
    error: null,
    types: TipoCampania[],
}

const initialState: TypesCampaignState = {

    loading: false,
    error: null,
    types: [],
};

export const typeCampaignReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case TYPE_CAMPAIGN_START_GETALL:
            return {
                ...state,
                loading: true
            }


        case TYPE_CAMPAIGN_GETALL:
            return {
                ...state,
                loading: false,
                types: [...action.payload]
            }

        case TYPE_CAMPAIGN_GETALL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }


        default:
            return state;

    }

}