import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";

const DashboardPage = lazy(() =>
    import("./components/dashboard/default/index")
);
const EcommercePage = lazy(() =>
    import("./components/dashboard/ecommerce")
);

const CampaignPage = lazy(() =>
    import("./components/campaign/CampaignScreen")
);

const CampaignCreatePage = lazy(() =>
    import("./components/campaign/CampaignCreateScreen")
);

const TemplatesSmsPage = lazy(() =>
    import("./components/templates-sms/TemplatesSmsScreen")
);

export const BasePage = () => {

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/app/dashboard"/>
                }
                <Route exact path="/app/dashboard" component={DashboardPage}/>
                <Route exact path="/app/ecommerce" component={EcommercePage}/>
                <Route exact path="/app/campaign" component={CampaignPage}/>
                <Route exact path="/app/campaignCreate" component={CampaignCreatePage}/>
                <Route exact path="/app/templates/sms" component={TemplatesSmsPage}/>
                <Redirect to="/app/dashboard"/>
            </Switch>
        </Suspense>
    );
}
