import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../redux/index';
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage/session'
import {encryptTransform} from "redux-persist-transform-encrypt";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'ui', 'Customizer', 'campaign', 'typeCampaigns'],
    transforms: [
        encryptTransform({
            secretKey: '@@$$ideadual.SMSM..',
            onError: function (error) {
                // Handle the error.
            },
        }),
    ],
}

const persistedReducer = persistReducer(
    persistConfig, reducers
)


const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)

// export default store;

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step


export {store, persistor}